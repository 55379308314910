<script lang="ts" setup>
import type { CmsElementBreadcrumb } from '~/types/models/cms/cmsElementTypes';

defineProps<{
    content: CmsElementBreadcrumb;
}>();

const { breadcrumbs } = useBreadcrumbs();
const { block } = useBlock();
if (!breadcrumbs.value?.length) {
    block.hidden = true;
}
</script>

<template>
    <div
        v-if="breadcrumbs?.length"
        class="pt-5 pb-7"
    >
        <SharedBreadcrumb :breadcrumbs="breadcrumbs" />
    </div>
</template>
